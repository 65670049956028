import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { renderToString } from 'react-dom/server'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import { pdfshift } from '../../agent'
import * as SchedulesStore from '../../store/reducers/schedules'
import Combined from './shift/Combined'
import Footer from './shift/Footer'
import {
  Icon
} from 'semantic-ui-react'

const css = require("!!css-loader!../../custom.css");
const _ = require('underscore')

type ReportProps =
    { pdf: any, missing: any, listings: string[], options: any, orientation: string } &
    typeof SchedulesStore.actionCreators &
    WithRouterProps<{}>

class CombinedPDF extends React.PureComponent<ReportProps> {
    public componentDidUpdate(prevProps:any) {
      if (!prevProps.pdf.ready && this.props.pdf.ready) {
        const pdf = renderToString(<Combined pdf={this.props.pdf} listings={this.props.listings} options={this.props.options} missing={this.props.missing} />);
        const footer = renderToString(<Footer pdf={this.props.pdf} options={this.props.options} />);

        pdfshift({
            source: pdf,
            footer: {
              source: footer,
              height: 70
            },
            margin: {
              top: 30,
              bottom: 0,
              left: 30,
              right: 30
            },
            landscape: this.props.orientation == 'landscape',
            css: css.default.toString(),
            format: 'Letter',
            wait_for: 'ready',
            filename: 'combined.pdf'
        }).then((response:any) => {
          this.props.setSchedulesURL('combined', response.data.url)
        })
      }
    }

    public render() {
        return (
          <Fragment>
            {this.props.pdf.error ? <div className='pdf'>
              <Icon.Group>
                <Icon name='warning sign' color='red' size='huge'  />
              </Icon.Group>
              <div className='name' style={{textAlign:'center'}}><b>Error</b></div>
            </div> : null}
            {!this.props.pdf.error && (this.props.pdf.generating || (this.props.pdf.ready && this.props.pdf.url.length == 0)) ? <div className='pdf generating'>
              <Icon.Group>
                <Icon name='circle notch' color='grey' size='huge' loading  />
                <Icon name='file pdf outline' color='grey' size='big' />
              </Icon.Group>
              <div className='name'><b>Generating PDF...</b></div>
            </div> : null}
            {!this.props.pdf.error && (this.props.pdf.ready && this.props.pdf.url.length > 0) ? <a target='_window' href={this.props.pdf.url}>
              <div className='pdf ready'>
                <Icon name='file pdf outline' size='huge' />
                <div className='name'><b>Master PDF</b></div>
              </div>
            </a> : null}
          </Fragment>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { pdf: ownProps.pdf, missing: ownProps.missing, listings: ownProps.listings, options: ownProps.options, orientation: ownProps.orientation } },
  SchedulesStore.actionCreators
)(CombinedPDF as any)